import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head';
import layoutStyles from '../styles/style.module.scss'

const ExperiencePage = () => {
    return (
        <div>
            <Head title= "experience"/>
            <Layout>
                <h1 className={layoutStyles.t1}>Relevant Professional Experience</h1>
                <nav>
                    <ul className={layoutStyles.indList}>
                        <li className={layoutStyles.t2}>HP Gregorio Consultancy - Environmental Consultant
                            <p className={layoutStyles.parag}>02/2014 - present</p>
                        </li>
                        <li className={layoutStyles.t2}>Mineral Engineering and Environmental, São Paulo, Brazil
                            <p className={layoutStyles.parag}>03/2011 - 01/2014</p>
                        </li>
                        <li className={layoutStyles.t2}>Consortium GeoHidroEco, Bogotá, Colombia
                            <p className={layoutStyles.parag}>08/2010 - 12/2010</p>
                        </li>
                        <li className={layoutStyles.t2}>Hidromares  Environmental  Assessment  and  Oceanography, Santos,  Brazil,
                            <p className={layoutStyles.parag}>03/2007 - 02/2011</p>
                        </li>
                        <li className={layoutStyles.t2}>Applied Science Association South America, São Paulo, Brazil
                            <p className={layoutStyles.parag}>01/2007 - 03/2007</p>
                        </li>
                    </ul>
                </nav>
                <p><br></br></p>
                <h1 className={layoutStyles.t1}>Relevant Research Experience</h1>
                <nav>
                    <ul className={layoutStyles.indList}>
                        <li className={layoutStyles.t2}>Laboratory of Coastal Hydrodynamic - University of São Paulo, Brazil
                            <p className={layoutStyles.parag}>03/2010 – 03/2014: Peregrino Environmental Monitoring and Calcareous Algae Project - Data Analysis (Fellowship: Statoil)</p>
                            <p className={layoutStyles.parag}>01/2007 - 12/2009: Pro-Abrolhos Project - Data Analysis (Fellowship: Petrobras)</p>
                            <p className={layoutStyles.parag}>05/2003 - 12/2006: Water Quality Modelling for Admiralty Bay, Antarctica - Scientific Initiation (Fellowship: National Council for Research and Development - Brazil)</p>
                        </li>
                        <li className={layoutStyles.t2}>Brazilian Antarctic Program  
                            <p className={layoutStyles.parag}>11/2004: Water Quality Modelling for Admiralty Bay, King George Island, Antarctica - 456 h in Brazilian Antarctic Estation Comandante Ferraz (Fellowship: National Council for Research and Development - Brazil)</p>
                            <p className={layoutStyles.parag}>11/2004: Water Quality Modelling for Admiralty Bay, South Sheetlands Islands, Antarctica - 275 h on Oceanographic Support Ship Ary Rongel/Brazil Navy (Fellowship: National Council for Research and Development - Brazil)</p>
                        </li>
                        <li className={layoutStyles.t2}> Deproas|BR Project - University of São Paulo and Petrobras, Brazil
                            <p className={layoutStyles.parag}>04/2004: Campos and Santos Basins, Brazil - 204 h on Oceanographic Ship W. Besnard (Fellowship: National Council for Research and Development - Brazil and Petrobras)</p>
                        </li>
                    </ul>
                </nav>
            </Layout>
        </div>
    )    
}

export default ExperiencePage